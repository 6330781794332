'use client'

import Link from 'next/link'

export interface ErrorProps {
  error: Error
}

export default function Error({ error }: ErrorProps) {
  return (
    <main className='min-h-screen flex'>
      <div className='m-auto flex flex-col items-center gap-8'>
        <h2 className='text-5xl leading-10 text-white'>Something went wrong!</h2>
        <p>{error?.message}</p>
        <Link className="inline-flex items-center gap-2.5 rounded-full bg-black px-6 py-3 font-medium text-white hover:opacity-90 dark:bg-white dark:text-black" href="/">Go Home</Link>
      </div>
    </main>
  )
}
